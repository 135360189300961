import styled from 'styled-components';
import { StyledButtonFilled, StyledButtonOutlined } from 'src/web-shared-components/base/Buttons';

export const StyledContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;
export const StyledPopover = styled.div`
  cursor: pointer;
  padding: 8px 10px;
  position: absolute;
  background: #f3f6fa; // theme color: mist
  border: 1px solid #e3e7ed; // theme color: pale grey
  border-radius: 4px;
  color: #73747d; // theme color: stone
  z-index: 10000;
  width: max-content;
  top: 30px;
`;

export const StyledModalTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

export const StyledModalDesc = styled.div`
  margin-top: 40px;
  > div:first-child {
    margin-bottom: 20px;
  }
`;

export const StyledButtonGroup = styled.div<{ $marginTop?: string }>`
  margin-top: ${(props) => props.$marginTop || '60px'};
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledModalContainer = styled.div`
  text-align: center;
`;

export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
`;
export const SubmitButton = styled(StyledButtonFilled)`
  padding: 20px;
`;
